<template>
  <v-app>
    <div style="height: 80px"/>
  <div id="app" style="width: 100%">
    <div style="" class="text-center">
      <v-row align="center" justify=" center">
        <v-col cols="12" class="text-center ml9 pa-0 ma-0">
          <span >
            <span class="letters" style="font-family: Sofia; ">
              <span class="letter" style="color:#3f807c">d</span>
              <span class="letter">e</span>
              <span class="letter">e</span>
              <span class="letter" style="color: #d77d78">s</span>
            </span>
          </span>
        </v-col>
        <v-col cols="12">
          <span style="font-size: 20px; font-family: Redressed; ">System & Design</span>
        </v-col>
      </v-row>
    </div>
  </div>
  </v-app>
</template>


<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  mounted() {
    // Wrap every letter in a span
    // var textWrapper = document.querySelector('.ml9 .letters');
    // textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");

    anime.timeline({loop: false})
        .add({
          targets: '.ml9 .letter',
          scale: [0, 1],
          duration: 3500,
          elasticity: 600,
          delay: (el, i) => 145 * (i + 1)
        }).add({
      targets: '.ml9',
      opacity: 0,
      duration: 1000,
      easing: "easeOutExpo",
      delay: 999999999
    });
  }
}

</script>

<style>
.ml9 {
  position: relative;
  font-weight: 200;
  font-size: 6em;
}

.ml9 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml9 .letter {
  transform-origin: 50% 100%;
  display: inline-block;
  line-height: 1em;
}
</style>
